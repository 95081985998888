"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _reduxoptimisticui = require('redux-optimistic-ui');




var _log = require('shared/log');
var _store = require('shared/store');
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _uuid = require('shared/services/uuid');

const mkMeta = (
  meta,
  type,
  id,
) => _optionalChain([meta, 'optionalAccess', _ => _.isOptimistic])
    ? { ...meta, optimistic: { type, id } }
    : meta

 const wsCommand = async ( 
  commandName,
  ac



,
  request,
  meta,
) => {
  const optimisticId = _optionalChain([meta, 'optionalAccess', _2 => _2.isOptimistic]) ? _uuid.uuidv4.call(void 0, ) : null
  _store.dispatch.call(void 0, ac.REQUEST(request, mkMeta(meta, _reduxoptimisticui.BEGIN, optimisticId)))

  // Capture here to retain the stack trace
  const cmdError = new (0, _log.CommandError)(commandName)

  return _client2.default.send(commandName, request)
    .then((payload) => {
      _store.dispatch.call(void 0, ac.SUCCESS(payload, mkMeta(meta, _reduxoptimisticui.COMMIT, optimisticId)))
      return payload
    })
    .catch((e) => {
      if (e instanceof Error) {
        _log.log.error(e)
        _store.dispatch.call(void 0, ac.FAILURE(e, mkMeta(meta, _reduxoptimisticui.REVERT, optimisticId)))
        throw e
      } else {
        cmdError.addExtra(e)
        if (e.Status === 'client_error') {
          switch (commandName) {
            // Don't capture unavoidable client errors
            case 'SetPassword':
              if (e.Error === 'incorrect password' || e.Error === 'weak password') break
            case 'AddPhone':
            case 'NewPatient':
            case 'ResendPhoneConfirmation':
            case 'VerifyPhone':
              if (e.Error === 'invalid phone number' || e.Error === 'unsupported region') break
              if (e.Error === 'Invalid token' || e.Error === 'Steal primary') break
            default:
              _log.log.error(cmdError)
          }
        }
        _store.dispatch.call(void 0, ac.FAILURE(e, mkMeta(meta, _reduxoptimisticui.REVERT, optimisticId)))
        throw cmdError
      }
    })
}; exports.wsCommand = wsCommand

exports. default = exports.wsCommand
