"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/ConversationScreen/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _querystring = require('query-string'); var _querystring2 = _interopRequireDefault(_querystring);
var _reactrouterdom = require('react-router-dom');
var _time = require('shared/services/time');
var _log = require('shared/log');
var _chat = require('shared/enum/chat');
var _message = require('shared/validation/chat/message');

var _reactdropzone = require('react-dropzone'); var _reactdropzone2 = _interopRequireDefault(_reactdropzone);
var _ExpandingInput = require('web/components/Conversation/ExpandingInput'); var _ExpandingInput2 = _interopRequireDefault(_ExpandingInput);

var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);

var _StoreConversationBox = require('web/screens/ConversationScreen/StoreConversationBox'); var _StoreConversationBox2 = _interopRequireDefault(_StoreConversationBox);
var _sort = require('shared/services/sort');
var _store = require('web/store');
var _user = require('shared/selectors/user');







var _conversation = require('shared/selectors/conversation');
var _blobject = require('shared/selectors/blobject');
var _myRelations = require('shared/selectors/myRelations');

var _ConfirmDeleteModal = require('web/components/Conversation/Message/ConfirmDeleteModal'); var _ConfirmDeleteModal2 = _interopRequireDefault(_ConfirmDeleteModal);
var _string = require('shared/services/string');
var _uuid = require('shared/services/uuid');
var _RejoinModal = require('./RejoinModal'); var _RejoinModal2 = _interopRequireDefault(_RejoinModal);
var _ui = require('web/services/ui');
var _navigation = require('web/actions/navigation');
var _noticeBar = require('web/services/context/noticeBar');

const MSGS_PER_BATCH = 30

class ConversationScreen extends _react.Component {
  static __initStatic() {this.contextType = _noticeBar.NoticeBarContext}
  constructor (props) {
    super(props);ConversationScreen.prototype.__init.call(this);ConversationScreen.prototype.__init2.call(this);ConversationScreen.prototype.__init3.call(this);ConversationScreen.prototype.__init4.call(this);ConversationScreen.prototype.__init5.call(this);ConversationScreen.prototype.__init6.call(this);ConversationScreen.prototype.__init7.call(this);ConversationScreen.prototype.__init8.call(this);ConversationScreen.prototype.__init9.call(this);ConversationScreen.prototype.__init10.call(this);ConversationScreen.prototype.__init11.call(this);ConversationScreen.prototype.__init12.call(this);ConversationScreen.prototype.__init13.call(this);ConversationScreen.prototype.__init14.call(this);ConversationScreen.prototype.__init15.call(this);ConversationScreen.prototype.__init16.call(this);ConversationScreen.prototype.__init17.call(this);ConversationScreen.prototype.__init18.call(this);

    this.state = {
      finishedInitialLoad: false,
      currentMessage: "",

      endReachedBelow: false,
      loadingAbove: false,
      loadingBelow: false,

      readOnly: true,
      messageToDelete: null,
    }
  }

  __init() {this.reset = () => {
    const { lastSeenTime, readOnly, draft, conversationKey, location } = this.props
    const values = _querystring2.default.parse(location.search) || {}
    const messageKey = values.messageKey
    const hasMessage = messageKey && messageKey !== _uuid.EMPTY_BLUID

    this.setState({
      endReachedBelow: false,
      loadingAbove: false,
      loadingBelow: false,
      finishedInitialLoad: false,
      currentMessage: draft,
      readOnly: readOnly,
    }, () => {
      const loadLatestMessages =
        () => _actions2.default.loadConversation({ ConversationKey: conversationKey, Limit: MSGS_PER_BATCH })

      const loadRelevantBatch = () => hasMessage
        ? _actions2.default.loadConversationAroundMessage({
          MessageKey: messageKey,
          ConversationKey: conversationKey,
          Limit: MSGS_PER_BATCH,
        })
        : _actions2.default.loadConversationAroundTime({
          Time: lastSeenTime,
          ConversationKey: conversationKey,
          Limit: MSGS_PER_BATCH,
        })

      loadRelevantBatch()
        .then(({ Conversations = {} }) => {
          const conversation = Conversations[conversationKey]
          if (!conversation) return loadLatestMessages()

          const conversationMaxSequence = conversation.MessageSequence
          const batchMaxSequence = Object.values(conversation.Messages)
            .reduce((acc, m) => Math.max(m.Sequence, acc), 0)

          // The batch we just loaded doesn't include latest messages,
          // need to pull them too to be able to "jump to the bottom".
          if (batchMaxSequence < conversationMaxSequence) {
            return loadLatestMessages()
          }
        })
        .catch(() => {})
        .finally(() => this.setState({ finishedInitialLoad: true }))
    })
  }}

  componentDidMount () {
    this.reset()
  }

  componentDidUpdate (prevProps) {
    const currentKey = this.props.conversationKey
    const prevKey = prevProps.conversationKey

    const values = _querystring2.default.parse(this.props.location.search) || {}
    const messageKey = values.messageKey

    const prevValues = _querystring2.default.parse(prevProps.location.search) || {}
    const prevMessageKey = prevValues.messageKey

    if (currentKey !== prevKey || messageKey !== prevMessageKey) {
      this.saveConversationDraft(prevKey, this.state.currentMessage)
      this.reset()
      const inputElem = document.getElementById("main-input")
      if (inputElem) {
        inputElem.focus()
      }
    }
  }

  componentWillUnmount () {
    const { conversationKey } = this.props
    const message = this.state.currentMessage
    this.saveConversationDraft(conversationKey, message)
  }

  __init2() {this.saveConversationDraft = (conversationKey, message) => {
    _actions2.default.saveConversationDraft({ conversationKey, message })
  }}

  __init3() {this.setDropZoneRef = (ref) => {
    this.dropzoneRef = ref
    this.chatBoxContainerHeight = this.dropzoneRef ? this.dropzoneRef.node.offsetHeight : 0
  }}

  __init4() {this.updateMessage = (msg) => {
    this.setState({
      currentMessage: msg,
    })
  }}

  __init5() {this.onDrop = acceptedFiles => acceptedFiles.forEach(this.sendFile)}

  __init6() {this.sendFile = f => {
    const { conversationKey } = this.props

    _actions2.default.sendConversationFile(conversationKey, f).catch(() => null)
  }}

  __init7() {this.sendMessage = () => {
    const conversationKey = this.props.conversationKey
    const values = _querystring2.default.parse(this.props.location.search) || {}
    const messageKey = values.messageKey
    const hasSelectedMessage = messageKey !== undefined
    const msg = _string.sanitizeStr.call(void 0, this.state.currentMessage)

    this.setState(
      { currentMessage: "" },
      () => msg && _actions2.default.sendConversationMessage(conversationKey, msg)
        .catch(() => null),
    )

    if (hasSelectedMessage) {
      _store.history.replace(this.props.location.pathname)

      /* a timeout because I guess the replace api is non-synchronous???? */
      setTimeout(this.reset, 50)
    }
  }}

  __init8() {this.onRetrySendMessage = (messageKey, text) => {
    const { conversationKey } = this.props
    const msg = _string.sanitizeStr.call(void 0, text)
    if (!msg) return

    _actions2.default.sendConversationMessage(conversationKey, msg, null, messageKey)
      .catch(() => null)
  }}

  __init9() {this.onRetrySendFile = (messageKey, fileKey) => {
    const { conversationKey } = this.props

    _actions2.default.sendConversationFile(conversationKey, null, messageKey, fileKey)
      .catch(() => null)
  }}

  __init10() {this.onAbortSendFile = (MessageKey, options = {}) => {
    _actions2.default.abortSendConversationFile(MessageKey)

    const { pruneTimeout } = options
    if (pruneTimeout) {
      const { conversationKey } = this.props
      setTimeout(
        () => _actions2.default.pruneLocalMessage({ ConversationKey: conversationKey, MessageKey }),
        pruneTimeout,
      )
    }
  }}

  __init11() {this.onMarkMessageSeen = message => {
    const { lastSeenTime, blobjectKey } = this.props

    if (
      !message || !message.MessageKey ||
      _time.isAfter.call(void 0, lastSeenTime, message.SentAt)
    ) return

    _actions2.default.markBlobjectSeen({ BKey: blobjectKey, SeenTime: message.SentAt })
      .catch(() => null)
  }}

  __init12() {this.onMarkConversationVisible = isVisible => {
    const { conversationKey } = this.props
    _actions2.default.markConversationVisible({ conversationKey, isVisible })
  }}

  __init13() {this.onCopyMessageLink = messageKey => {
    const { blobjectKey, conversationKey, conversationType } = this.props
    const { showNoticeBar } = this.context
    const blobjectRoute = _navigation.buildBlobjectRoute.call(void 0, {
      blobjectKey, conversationType, conversationKey, messageKey,
    })
    const blobjectURL = _navigation.makeFullURL.call(void 0, blobjectRoute)

    _ui.copyToClipboard.call(void 0, blobjectURL)
      .then(() => showNoticeBar({ type: 'success', text: 'Link has been copied' }))
  }}

  __init14() {this.onDeleteMessage = (messageKey, conversationKey) =>
    this.setState({
      messageToDelete: { messageKey, conversationKey },
    })}

  __init15() {this.clearMessageToDelete = () => this.setState({ messageToDelete: null })}

  __init16() {this.onConfirmDeleteMessage = () => {
    if (this.state.messageToDelete) {
      const { messageKey, conversationKey } = this.state.messageToDelete
      const { showNoticeBar } = this.context
      this.clearMessageToDelete()
      _actions2.default.deleteMessageFromConversation(messageKey, conversationKey)
        .catch(() => showNoticeBar({ type: 'warning', text: 'Failed to recall message' }))
    }
  }}

  __init17() {this.onLoadAbove = (earliestMessage) => {
    if (earliestMessage.Sequence === 1 || !this.state.finishedInitialLoad) {
      return Promise.resolve()
    }

    this.setState({ loadingAbove: true })

    return _actions2.default.loadConversation({
      ConversationKey: this.props.conversationKey,
      Limit: MSGS_PER_BATCH,
      Seq: earliestMessage.Sequence,
    })
      .then(() => this.setState({ loadingAbove: false }))
      .catch(() => null)
  }}

  __init18() {this.onLoadBelow = (latestMessage) => {
    if (
      latestMessage.Sequence >= this.props.totalMessagesCount ||
      !this.state.finishedInitialLoad ||
      this.state.endReachedBelow ||
      this.state.loadingBelow
    ) {
      return Promise.resolve()
    }

    this.setState({ loadingBelow: true })
    const { conversationKey } = this.props
    return _actions2.default.loadConversation({
      ConversationKey: conversationKey,
      Limit: MSGS_PER_BATCH,
      Seq: latestMessage.Sequence + MSGS_PER_BATCH,
    })
      .then(res => {
        const conversation = res.Conversations && Object.keys(res.Conversations).length > 0 ? Object.values(res.Conversations)[0] : null
        if (conversation === null) {
          throw new Error("Invalid chat key")
        }

        const messages = conversation.Messages && Object.values(conversation.Messages).length > 0 ? Object.values(conversation.Messages) : null
        if (messages == null) {
          throw new Error("No Messages")
        }

        const latestServerMessage = messages.sort((a, b) => _sort.sortByInt.call(void 0, a.Sequence, b.Sequence))[0]

        this.setState({
          loadingBelow: false,
          endReachedBelow: latestServerMessage.Sequence === latestMessage.Sequence,
        })
      })
      .catch(_log.log.error)
  }}

  render () {
    const {
      blobjectKey,
      conversationKey,
      conversationType,
      location,
      readOnly,
      myOrgKey,
      amIOrgless,
    } = this.props

    const values = _querystring2.default.parse(location.search) || {}
    const messageKey = values.messageKey
    const canDeleteMessages = conversationType !== _chat.CHAT_TYPE.REGULAR_PATIENT

    return (
      _react2.default.createElement('div', { className: "chat--messagingSection", __self: this, __source: {fileName: _jsxFileName, lineNumber: 326}}
        , _react2.default.createElement(_reactdropzone2.default, {
          onDrop: this.onDrop,
          className: "chatbox--dropzone",
          ref: this.setDropZoneRef,
          disableClick: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 327}}
        
          , _react2.default.createElement(_StoreConversationBox2.default, {
            key: conversationKey + messageKey,
            highlightedMessageKey: messageKey,
            conversationKey: conversationKey,
            blobjectKey: blobjectKey,
            isLoadingAbove: this.state.loadingAbove,
            isLoadingBelow: this.state.loadingBelow,
            onLoadAbove: this.onLoadAbove,
            onLoadBelow: this.onLoadBelow,
            onDelete: canDeleteMessages ? this.onDeleteMessage : null,
            onCopyMessageLink: this.onCopyMessageLink,
            onRetrySendMessage: this.onRetrySendMessage,
            onRetrySendFile: this.onRetrySendFile,
            onAbortSendFile: this.onAbortSendFile,
            onMarkMessageSeen: this.onMarkMessageSeen,
            onMarkConversationVisible: this.onMarkConversationVisible, __self: this, __source: {fileName: _jsxFileName, lineNumber: 333}}
          )
        )
        , _react2.default.createElement(_ConfirmDeleteModal2.default, {
          isOpen: !!this.state.messageToDelete,
          onClose: this.clearMessageToDelete,
          onProceed: this.onConfirmDeleteMessage, __self: this, __source: {fileName: _jsxFileName, lineNumber: 351}}
        )
        , _react2.default.createElement('div', { className: "chat--createMessageBox", __self: this, __source: {fileName: _jsxFileName, lineNumber: 356}}
          , _react2.default.createElement(_ExpandingInput2.default, {
            onChange: (_err, value) => this.updateMessage(value),
            maxLength: conversationType === 'sms_patient_conversation'
              ? _message.MAX_SMS_LENGTH
              : _message.MAX_MESSAGE_LENGTH
            ,
            onAttachmentClick: () => this.dropzoneRef.open(),
            onSendFile: this.sendFile,
            onSend: this.sendMessage,
            value: this.state.currentMessage,
            disabled: readOnly, __self: this, __source: {fileName: _jsxFileName, lineNumber: 357}}
          )
        )
        , _react2.default.createElement(_RejoinModal2.default, {
          isOpen: myOrgKey === _uuid.EMPTY_BLUID && !amIOrgless,
          conversationKey: conversationKey,
          onClose: _actions2.default.gotoInbox, __self: this, __source: {fileName: _jsxFileName, lineNumber: 370}}
        )
      )
    )
  }
} ConversationScreen.__initStatic();

const ConversationScreenShim = _reactrouterdom.withRouter.call(void 0, 
  (props) => props.lastSeenTime
    ? _react2.default.createElement(ConversationScreen, { ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 382}} )
    : null,
)

const mapState = (state, { blobjectKey, conversationKey }) => {
  const currentUserKey = _user.selectCurrentUserKey.call(void 0, state)
  const lastSeenTime = _blobject.selectMyBlobjectLastSeenTime.call(void 0, state, blobjectKey)
  const draft = _conversation.selectDraftByConversationKey.call(void 0, state, conversationKey) || ''
  const readOnly =
    _conversation.selectShouldShowUpgradeNotice.call(void 0, state, blobjectKey) ||
    _conversation.selectShouldShowPatientUpgradeNotice.call(void 0, state, blobjectKey)
  const conversation = _conversation.selectConversationByKey.call(void 0, state, conversationKey)
  const conversationType = _conversation.selectConversationType.call(void 0, state, blobjectKey)
  const myOrgKey = _conversation.selectMyOrgKeyInConvo.call(void 0, state, blobjectKey)
  const amIOrgless = _myRelations.selectAmIOrgless.call(void 0, state)
  const totalMessagesCount = _optionalChain([conversation, 'optionalAccess', _ => _.MessageSequence])

  return {
    currentUserKey,
    myOrgKey,
    amIOrgless,
    lastSeenTime,
    draft,
    readOnly,
    conversationType,
    totalMessagesCount,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(ConversationScreenShim)
